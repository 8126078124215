import React from "react"
import Img from "gatsby-image"
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Col, Row, } from "react-bootstrap"
import styled from 'styled-components';

export default ({ data }) => {
  const post = data.markdownRemark;
  const Dot = styled.span`
        &:after {
          content: '•';
        margin: 0 5px;}
        `
        const BlogImage = styled(Img)`
        width:50%;
        margin:auto;
        margin-top:3rem;
        margin-bottom:3rem;
        @media(max-width: 768px) {
        width:100%;
        margin-top:3rem;
        margin-bottom:3rem;
        }
        `
        const SubscribeButtonWrapper = styled.div`
        display:flex;
        text-align:center;
        width: 38rem;
        margin-top: 0.2rem;
        margin-bottom:1rem;
           justify-content: space-evenly;
           margin-right: auto;
           margin-left: auto;
           @media (max-width: 600px) {
          width:auto;
          flex-flow: column;
           }
       
          `
           const SubscribeButton = styled.div`
       background-color: #6efbc5;
       padding: .5rem;
       color: #333333;
       font-weight: 500;
       @media (max-width: 600px) {
       margin-top:1rem;
          }   
         `
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={data.markdownRemark.excerpt}
        image={post.frontmatter.BlogImage.childImageSharp.fluid.src}  />
      <Container>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1>Blog</h1>
        </div>
        <h2 className='controllerheadtext' style={{ width: "100%" }}>{post.frontmatter.title}<Dot />{post.frontmatter.date}</h2>
        <BlogImage
          fluid={post.frontmatter.BlogImage.childImageSharp.fluid}
          alt={post.frontmatter.BlogImageAlt}
        />
        <div className='post-content' dangerouslySetInnerHTML={{ __html: post.html }} />
        <br></br>
        <div className='prolacs-divider' style={{ height: '100px' }}>
          <div className='prolacs-divider-content-1' style={{ top: '22px', position: 'relative' }}>
            <p> Prolacs can <span className='color-content'></span>  every parameter you want to know.
            </p>
            <SubscribeButtonWrapper>
              <Link to='/contact'><SubscribeButton>Request a Demo </SubscribeButton></Link>
            </SubscribeButtonWrapper>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        BlogImage {
          childImageSharp {
            fluid (quality:100, maxWidth:900) {
             ...GatsbyImageSharpFluid_withWebp_tracedSVG
             src
            }
          }
        }
      }
      excerpt(pruneLength: 150)
    }
  }
  
`;